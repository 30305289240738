<template>
<!-- ======= Portfolio Section ======= -->
<section id="portfolio" class="portfolio section-bg">
    <div class="container">

        <div class="section-title">
            <h2 data-aos="fade-in">{{ $t('portfolio_cap') }}</h2>
            <p data-aos="fade-in">{{ $t("portfolio_description") }}</p>
        </div>

        <div class="row">
            <div class="col-lg-12">
                <ul id="portfolio-flters">
                    <li @click="filterItems('all')" class="filter-active">{{ $t('portfolio_all') }}</li>
                    <li @click="filterItems('web')">{{ $t('portfolio_web') }}</li>
                    <li @click="filterItems('mobile')">{{ $t('portfolio_mobile') }}</li>
                    <li @click="filterItems('platform')">{{ $t('portfolio_platform') }}</li>
                    <li @click="filterItems('ecommerce')">{{ $t('portfolio_ecommerce') }}</li>
                    <li @click="filterItems('inhouse')">{{ $t('portfolio_inhouse') }}</li>                    
                </ul>
            </div>
        </div>

        <isotope class="row portfolio-container" data-aos="fade-up" :options='options' :list="GetList()" @filter="filterOption=arguments[0]" @sort="sortOption=arguments[0]" ref="isotopeComponent">
            <div v-for="element in GetList()" :key="element.id" class="col-lg-6 col-md-6 portfolio-item filter-app">
                <div class="portfolio-wrap">
                    <div class="card">
                        <div class="card-img" :ref="'cardimg'+element.id+'_ref'">
                            <img :style="{ height: currentimgH+'px' }" :src="getImage(element.image)" :alt="element.title[$i18n.locale]">
                            <!-- <div class="portfolio-links">
                                <a href="./../assets/img/portfolio/portfolio-2.jpg" data-gall="portfolioGallery" class="venobox" title="Web 3"><i class="icofont-plus-circle"></i></a>
                                <a href="#" title="More Details"><i class="icofont-link"></i></a>
                            </div> -->
                        </div>
                        <div class="card-body">
                            <h5 class="card-title"><a href="javascript:void(0)" @click="showPanel(element)">{{ element.title[$i18n.locale] }}</a></h5>
                            <!-- <p class="card-text">{{ element.shortdesc[$i18n.locale] }}</p> -->
                            <div v-html="element.shortdesc[$i18n.locale]" class="card-text"></div>
                            <div class="read-more"><a href="javascript:void(0)" @click="showPanel(element)"><i class="icofont-arrow-right"></i>{{ $t('readmore') }}</a></div>
                        </div>
                    </div>
                </div>
            </div>
        </isotope>
    </div>

    <!-- <slideout-panel @hidePanel="hidePanel()"></slideout-panel> -->
</section><!-- End Portfolio Section -->
</template>

<script>
/* import JQuery from 'jquery';
window.$ = JQuery; */
import isotope from 'vueisotope';
import PortfolioPanel from './PortfolioPanel';

export default {
    name: 'Portfolio',
    components: {
        isotope,
        PortfolioPanel
    },
    data() {
        return {
            /* list: [{
                    id: 1,
                    type: 'platform',
                    title: {
                        en: 'Keyonic Smart Building Automation',
                        el: 'Keyonic Εξυπνη Διαχείριση Κτιρίων'
                    },
                    shortdesc: {
                        en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
                        el: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
                    },
                    description: {
                        en: '',
                        el: ''
                    },
                    image: 'keyonic.jpg',
                    path: 'keyonic'
                },
                {
                    id: 2,
                    type: 'platform',
                    title: {
                        en: 'Feel Music Application',
                        el: 'Feel Music Επαγγελματική Εφαρμογή Μουσικής'
                    },
                    shortdesc: {
                        en: 'The Ultimate Music App by FeelMusic',
                        el: 'Η Απόλυτη Μουσική Εφαρμογή της FeelMusic'
                    },
                    description: {
                        en: 'With Feel Music app you have at your disposal more than 100,000 songs, divided into 120+ categories according to their species and tempo. With a single click you can select a music category, create your daily routine and make your own infinite favorite playlists.',
                        el: 'Με την εφαρμογή της Feel Music έχετε στην διάθεση σας περισσότερα από 100.000 τραγούδια χωρισμένα σε 130+ κατηγορίες ανάλογα με το είδος τους και το τέμπο τους. Μπορείτε με ένα μόνο κλικ να επιλέξετε μουσική κατηγορία, να δημιουργήσετε το καθημερινό σας πρόγραμμα και να φτιάξετε απεριόριστες δικές σας αγαπημένες playlists.'
                    },
                    image: 'feelmusic.jpg',
                    path: 'feelmusic'
                },
                {
                    id: 3,
                    type: 'desktop',
                    title: {
                        en: 'Keema In Store Music Application',
                        el: 'Εφαρμογή Keema'
                    },
                    shortdesc: {
                        en: '',
                        el: ''
                    },
                    description: {
                        en: '',
                        el: ''
                    },
                    image: 'keema.jpg',
                    path: 'keema'
                },
                {
                    id: 4,
                    type: 'platform',
                    title: {
                        en: 'Cosmoplay Games 2018',
                        el: 'Παιχνίδια Cosmoplay 2018'
                    },
                    shortdesc: {
                        en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
                        el: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
                    },
                    description: {
                        en: '',
                        el: ''
                    },
                    image: 'cosmoplay.jpg',
                    path: 'cosmoplay'
                },
                {
                    id: 5,
                    type: 'mobile',
                    title: {
                        en: 'Cosmote Books',
                        el: 'Cosmote Books'
                    },
                    shortdesc: {
                        en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
                        el: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
                    },
                    description: {
                        en: '',
                        el: ''
                    },
                    image: 'cosmotebooks.jpg',
                    path: 'cosmotebooks'
                },
                {
                    id: 6,
                    type: 'web',
                    title: {
                        en: 'Ιστοσελίδα ΥΠΕΚΑ Σχεδίων Διαχείρισης Κινδύνων Πλημμύρας',
                        el: 'Ιστοσελίδα ΥΠΕΚΑ Σχεδίων Διαχείρισης Κινδύνων Πλημμύρας'
                    },
                    shortdesc: {
                        en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
                        el: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
                    },
                    description: {
                        en: '',
                        el: ''
                    },
                    image: 'floods.ypeka.jpg',
                    path: 'floodsypeka'
                },
                {
                    id: 7,
                    type: 'platform',
                    title: {
                        en: 'Knowledge game MindJam',
                        el: 'Παιχνίδι Γνώσεων MindJam'
                    },
                    shortdesc: {
                        en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
                        el: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
                    },
                    description: {
                        en: '',
                        el: ''
                    },
                    image: 'mindjam.jpg',
                    path: 'mindjam'
                }
            ], */
            options: {
                layout: 'masonry',
                getFilterData: {
                    all: function (item) {
                        return true;
                    },
                    mobile: function (item) {
                        return item.type === 'mobile';
                    },
                    platform: function (item) {
                        return item.type === 'platform' || item.type === 'mobile';
                    },
                    web: function (item) {
                        return item.type === 'web';
                    },
                    desktop: function (item) {
                        return item.type === 'desktop';
                    },
                    inhouse: function (item) {
                        return item.type === 'inhouse';
                    },
                    ecommerce: function (item) {
                        return item.type === 'ecommerce';
                    }
                },
                sortBy: "id"
            },
            selected: null,
            sortOption: null,
            filterOption: null,
            filterText: "",
            currentimgH: 400
        }
    },
    created() {
        window.addEventListener("resize", this.onWindowResize);
    },
    mounted() {
        var self = this;
        setTimeout(function () {
            try {
                self.$refs.isotopeComponent.filter('all');
            } catch (ex) {

            }
        }, 500);

        //console.log(this.$refs.cardimg1_ref);
        try {
            this.currentimgH = this.$refs.cardimg1_ref[0].clientWidth * 400 / 600;
        } catch (ex) {

        }
    },
    methods: {
        GetList() {
            var finallist = [];
            console.log('this.$store.state.portfolio: ',this.$store.state.portfolio);
            if(this.$store.state.portfolio !== null && typeof this.$store.state.portfolio !== 'undefined')
            {
                for(var key in this.$store.state.portfolio)
                {
                    finallist.push(this.$store.state.portfolio[key]);
                }
            }

            return finallist;
        },
        filterItems(key) {
            this.$refs.isotopeComponent.filter(key);
        },
        showPanel(clickedelement) {
            var self = this;
            console.log('showPanel');
            
            this.$router.push({
                path: '/portfolioDetails'+'_'+clickedelement.path,
                params: {
                    title: clickedelement.title,
                    description: clickedelement.description
                }
            }).catch((err)=>{
                console.log("portfolio router error: ", err);
            });
            //this.$router.go();
        },
        hidePanel() {
            this.panel.hide();
        },
        getImage(image) {
            if (image === "") image = 'default.jpg';
            var finalpath = this.path + `${image}`;
            return require(`./../assets/img/portfolio/${image}`);
        },
        onWindowResize(e) {
            //console.log(this.$refs.cardimg1_ref.clientWidth);
            if (this.$refs.cardimg1_ref !== null && typeof this.$refs.cardimg1_ref !== 'undefined') {
                if (this.$refs.cardimg1_ref[0] !== null && typeof this.$refs.cardimg1_ref[0] !== 'undefined') {
                    this.currentimgH = this.$refs.cardimg1_ref[0].clientWidth * 400 / 600;
                }
            }

        }
    }
}
</script>

<style lang="scss" scoped>
.portfolio .portfolio-wrap::before {
    background: none;
    display: none;
}

.card-img {
    overflow: hidden;
}

.card-img img {
    position: relative;
}
</style>
