<template>
<!-- ======= Contact Section ======= -->
<section id="contactus" class="contact section-bg">
    <div class="container">

        <div class="section-title">
            <h2 data-aos="fade-in">{{ $t("contactus_cap") }}</h2>
            <p data-aos="fade-in">{{ $t("contact_description") }}</p>
        </div>

        <div class="row">

            <div class="col-lg-6">

                <div class="row">
                    <div class="col-md-12">
                        <div class="info-box" data-aos="fade-up">
                            <i class="bx bx-map"></i>
                            <h3>{{ $t('our_address') }}</h3>
                            <p>{{ $t('company_address') }}</p>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="info-box mt-4" data-aos="fade-up" data-aos-delay="100">
                            <i class="bx bx-envelope"></i>
                            <h3>{{ $t('email_us') }}</h3>
                            <p>info [- at -] joyfoundry.com</p>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="info-box mt-4" data-aos="fade-up" data-aos-delay="100">
                            <i class="bx bx-phone-call"></i>
                            <h3>{{ $t('call_us') }}</h3>
                            <p>+30 216 700 57 08<br></p>
                        </div>
                    </div>
                </div>

            </div>

            <div class="col-lg-6">
                <form action="contact.php" method="post" role="form" class="php-email-form" data-aos="fade-up" ref="contactform">
                    <div class="form-row">
                        <div class="col-md-6 form-group">
                            <input type="text" v-model="contactname" class="form-control" name="contactname" id="contactname" :placeholder="$t('contactform_name')" data-rule="minlen:4" data-msg="Please enter at least 4 chars" />
                            <div class="validate"></div>
                        </div>
                        <div class="col-md-6 form-group">
                            <input type="email" v-model="email" class="form-control" name="email" id="email" :placeholder="$t('contactform_email')" data-rule="email" data-msg="Please enter a valid email" />
                            <div class="validate"></div>
                        </div>
                    </div>
                    <div class="form-group">
                        <input type="text" v-model="phone" class="form-control" name="phone" id="phone" :placeholder="$t('contactform_phone')" data-rule="minlen:4" data-msg="Please enter at least 8 chars of subject" />
                        <div class="validate"></div>
                    </div>
                    <div class="form-group">
                        <input type="text" v-model="subject" class="form-control" name="subject" id="subject" :placeholder="$t('contactform_subject')" data-rule="minlen:4" data-msg="Please enter at least 8 chars of subject" />
                        <div class="validate"></div>
                    </div>
                    <div class="form-group">
                        <textarea class="form-control" v-model="message" name="message" id="message" rows="5" data-rule="required" data-msg="Please write something for us" :placeholder="$t('contactform_message')"></textarea>
                        <input type="hidden" v-model="tm" id="tm" name="tm" />
                        <div class="validate"></div>
                    </div>
                    <div class="mb-3">
                        <div class="loading" :class="{ evisible: loading_visible }">{{ $t('loading') }}</div>
                        <div class="error-message" :class="{ evisible: error_visible }">{{ errormessage }}</div>
                        <div class="sent-message" :class="{ evisible: success_visible }">{{ $t('contactform_sentmsg') }}</div>
                        <br />
                        <div class="text-center">
                            <vue-recaptcha @verify="onVerify" @expired="onExpired" sitekey="6LfNkigaAAAAAEGA8ssq3H79P3WO91pDju9nz5JF">
                            </vue-recaptcha>
                            <br />
                            <button type="submit" @click="SubmitContactForm()">{{ $t("contactform_send") }}</button>
                        </div>
                    </div>
                    <!-- <div class="text-center"><button type="submit" @click="SubmitContactForm()">{{ $t("contactform_send") }}</button></div> -->
                </form>
            </div>

        </div>

    </div>
</section><!-- End Contact Section -->
</template>

<script>
import VueRecaptcha from 'vue-recaptcha';
import axios from "axios";

export default {
    name: 'Contact',
    components: {
        VueRecaptcha
    },
    data() {
        return {
            contactname: '',
            email: '',
            phone: '',
            subject: '',
            message: '',
            tm: '',
            errormessage: '',
            error_visible: false,
            loading_visible: false,
            success_visible: false,
            captchavalid: false
        }
    },
    methods: {
        SubmitContactForm() {
            event.preventDefault();

            var self = this;

            this.tm = "jfcontact_" + new Date().getTime();

            if (this.contactname === '' || this.email === '' || this.subject === '' || this.message === '' || this.phone === '') {
                this.errormessage = this.$t('contact_error_allfields');
                this.error_visible = true;
            } else {
                if (this.validateEmail(this.email)) {
                    //this.captchavalid = true;
                    if (this.captchavalid) {
                        //this.$refs.contactform.submit();

                        var url = this.$refs.contactform.getAttribute("action");
                        //data = this.$refs.contactform.serialize();

                        console.log('url: ', url);
                        //console.log('data: ',data);

                        var data = {
                            contactname: this.contactname,
                            email: this.email,
                            phone: this.phone,
                            subject: this.subject,
                            message: this.message,
                            tm: this.tm
                        }

                        console.log(data);
                        //url = 'https://www.joyfoundry.com/contact.php';

                        /* axios.post(url, data)
                            .then(response => {
                                console.log(response.data);
                            }); */

                        var xhttp = new XMLHttpRequest();
                        xhttp.open("POST", 'contact.php', true);
                        xhttp.setRequestHeader("Content-Type", "application/json");
                        xhttp.onreadystatechange = function () {
                            if (xhttp.readyState == 4 && xhttp.status == 200) {                                
                                var response = xhttp.responseText;
                                console.log(response);
								try
								{
									var result = JSON.parse(response);
									if(result.r === 1)
									{
                                        console.log('success');
                                        self.success_visible = true;
									} else {
                                        self.errormessage = self.$t('contact_error_server');
                                        self.error_visible = true;
										console.log('fail');
									}
								} catch(ex)
								{
                                    if(response === '{"r": 1}')
                                    {
                                        self.success_visible = true;
                                    } else {
                                        self.errormessage = self.$t('contact_error_server');
                                        self.error_visible = true;
                                    }
								}
                            }
                        };
                        xhttp.send(JSON.stringify(data));
                    } else {
                        this.errormessage = this.$t('contact_error_captcha');
                        this.error_visible = true;
                    }

                } else {
                    this.errormessage = this.$t('contact_error_email');
                    this.error_visible = true;
                }
            }

            if (this.error_visible) {
                var self = this;
                setTimeout(function () {
                    self.error_visible = false;
                }, 3000);
            }
        },
        validateEmail(email) {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
        },
        onSubmit: function () {
            this.$refs.invisibleRecaptcha.execute();
        },
        onVerify: function (response) {
            console.log('Verify: ' + response);
            this.captchavalid = true;
        },
        onExpired: function () {
            console.log('Expired');
            this.captchavalid = false;
        },
        resetRecaptcha() {
            this.$refs.recaptcha.reset(); // Direct call reset method
        }
    }
}
</script>

<style lang="scss" scoped>
.evisible {
    display: block !important;
}

.ehidden {
    display: none !important;
}
</style>
