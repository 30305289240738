<template>
    <!-- ======= Hero Section ======= -->
    <section id="hero" ref="heroref">
        <div class="container">
            <div class="row d-flex align-items-center">
                <div class="col-lg-12 py-5 py-lg-0 order-2 order-lg-1 hero_container" data-aos="fade-right">
                    <h2>{{ $t('siteheader') }}</h2>
                    <div v-html="$t('sitesubheader')" style="max-width: 75%; margin: 0px auto;"></div>
                    <!-- <a href="#about" class="btn-get-started scrollto">{{ $t('getquote') }}</a> -->
                    <router-link class="btn-get-started scrollto" :to="{ name: 'eshop' }">{{ $t('getquote') }}</router-link>
                </div>               
            </div>
        </div>
        <video class="bgvideo" playsinline autoplay muted loop :height="videoHeight" hei id="bgvid">
            <source src="./../assets/img/jfbgvid.webm" type="video/webm">
        </video>
    </section>
    <!-- End Hero -->
</template>

<script>
export default {
    name: 'Hero',
    components: {        
    },
    data() {
        return {            
            videoHeight: 500
        }
    },
    created() {
        window.addEventListener("resize", this.onWindowResize);
    },
    mounted() {
        if (this.$refs.heroref !== null && typeof this.$refs.heroref !== 'undefined') {
            this.videoHeight = this.$refs.heroref.clientHeight;
        }
    },
    methods: {
        onWindowResize(e) {
            if (this.$refs.heroref !== null && typeof this.$refs.heroref !== 'undefined') {
                this.videoHeight = this.$refs.heroref.clientHeight;
            }
        }
    }
}
</script>

<style scoped lang="scss">
.bgvideo {
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: -2;
}

.btn-get-started {
    margin-top: 30px;
}

#hero {
    padding-top: 100px !important;
    padding-bottom: 60px !important;
    max-height: 555px;
}

#hero h2 {
    font-size: 29pt;
    color: #fda334;
}

#hero div {
    font-size: 16pt;
    color: #ffffff;
}

.sub {
    font-size: 20pt;
}
</style>
