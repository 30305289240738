import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Profile from '../views/Profile.vue'
import EShop from '../views/EShop.vue'
import PortfolioDetails from '../views/PortfolioDetails.vue'

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      title: 'JoyFoundry LTD | Mobile and Web Development solutions. Smart City and Building Applications',
      title_el: 'JoyFoundry ΕΠΕ | Ανάπτυξη Mobile και Web εφαρμογών. Eφαρμογές Smart Cities και διαχείρισης κτιριακών εγκαταστάσεων',
      metaTags: [
        {
          name: 'description',
          content: 'We are a Greek IT company with an international orientation. We believe in building the future with new innovative ideas and state-of-the-art products with reference to the domestic and international market.',
          content_el: 'Σχεδιασμός και ανάπτυξη διαδικτυακών εφαρμογών και εφαρμογών για φορητές συσκευές'
        },
        {
          property: 'og:description',
          content: 'We are a Greek IT company with an international orientation. We believe in building the future with new innovative ideas and state-of-the-art products with reference to the domestic and international market.',
          content_el: 'Σχεδιασμός και ανάπτυξη διαδικτυακών εφαρμογών και εφαρμογών για φορητές συσκευές'
        }
      ]
    }
  },
  {
    path: '/',
    name: 'about',
    hash: 'about',
    component: Home,
    meta: {
      title: 'About',
      title_el: 'About',
      metaTags: [
        {
          name: 'description',
          content: 'Software Development and Design Company',
          content_el: ''
        },
        {
          property: 'og:description',
          content: 'Software Development and Design Company',
          content_el: ''
        }
      ]
    }
  },
  {
    path: '/profile',
    name: 'profile',
    hash: 'profile',
    component: Profile,
    meta: {
      title: 'Profile',
      title_el: 'Προφίλ',
      metaTags: [
        {
          name: 'description',
          content: 'Our company is a dynamic and innovative IT company that develops and provides high-tech software products and quality services to businesses. Our vision is to offer customers the best possible solutions while at the same time being able to contribute to the success of our customers. We are constantly investing in the development of our products and technologies, so that we are constantly at the forefront of technology. We want the projects we deliver to be perfect and without problems while at the same time to be user friendly.',
          content_el: 'Η εταιρεία μας είναι μια δυναμική και καινοτομική εταιρεία πληροφορικής που αναπτύσσει και παρέχει προϊόντα λογισμικού υψηλής τεχνολογίας και ποιοτικές υπηρεσίες σε επιχειρήσεις. Όραμά μας είναι να προσφέρουμε στους πελάτες τις καλύτερες δυνατές λύσεις ενω ταυτόχρονα να μπορούμε να συμβάλλουμε και εμέις στην επιτυχία των πελατών μας. Επενδύουμε συνεχώς στην εξέλιξη των προιόντων μας και των τεχνολογιών μας, ώστε να βρισκόμαστε συνεχώς στην αιχμή της τεχνολογίας. Θέλουμε τα έργα που παραδίδουμε, να είναι άρτια και χωρίς προβλήματα ενώ ταυτόχρονα να είναι φιλικά πρός τους χρήστες.'
        },
        {
          property: 'og:description',
          content: 'Our company is a dynamic and innovative IT company that develops and provides high-tech software products and quality services to businesses. Our vision is to offer customers the best possible solutions while at the same time being able to contribute to the success of our customers. We are constantly investing in the development of our products and technologies, so that we are constantly at the forefront of technology. We want the projects we deliver to be perfect and without problems while at the same time to be user friendly.',
          content_el: 'Η εταιρεία μας είναι μια δυναμική και καινοτομική εταιρεία πληροφορικής που αναπτύσσει και παρέχει προϊόντα λογισμικού υψηλής τεχνολογίας και ποιοτικές υπηρεσίες σε επιχειρήσεις. Όραμά μας είναι να προσφέρουμε στους πελάτες τις καλύτερες δυνατές λύσεις ενω ταυτόχρονα να μπορούμε να συμβάλλουμε και εμέις στην επιτυχία των πελατών μας. Επενδύουμε συνεχώς στην εξέλιξη των προιόντων μας και των τεχνολογιών μας, ώστε να βρισκόμαστε συνεχώς στην αιχμή της τεχνολογίας. Θέλουμε τα έργα που παραδίδουμε, να είναι άρτια και χωρίς προβλήματα ενώ ταυτόχρονα να είναι φιλικά πρός τους χρήστες.'
        }
      ]
    }
  },
  {
    path: '/eshop',
    name: 'eshop',
    hash: 'eshop',
    component: EShop,
    meta: {
      title: 'Δωρεάν e-Shop - 100% επιδότηση',
      title_el: 'Δωρεάν e-Shop - 100% επιδότηση',
      metaTags: [
        {
          name: 'description',
          content: 'Νέο πρόγραμμα ΕΣΠΑ. Αναλαμβάνουμε την κατασκευή και την υποβολή του φακέλου του e-Shop σας',
          content_el: 'Νέο πρόγραμμα ΕΣΠΑ. Αναλαμβάνουμε την κατασκευή και την υποβολή του φακέλου του e-Shop σας'
        },
        {
          property: 'og:description',
          content: 'Νέο πρόγραμμα ΕΣΠΑ. Αναλαμβάνουμε την κατασκευή και την υποβολή του φακέλου του e-Shop σας',
          content_el: 'Νέο πρόγραμμα ΕΣΠΑ. Αναλαμβάνουμε την κατασκευή και την υποβολή του φακέλου του e-Shop σας'
        }
      ]
    }
  },
  {
    path: '/',
    name: 'services',
    hash: 'services',
    component: Home,
    meta: {
      title: 'Services',
      title_el: 'Υπηρεσίες',
      metaTags: [
        {
          name: 'description',
          content: 'Our company with more than 10 years of experience, offers unique IT and digital content design solutions',
          content_el: 'Η Εταιρεία μας με πάνω απο 10 χρόνια εμπειρίας στο χώρο, σας προσφέρει μοναδικές λύσεις πληροφορικής και δημιουργίας ψηφιακού περιεχομένου για κάθε σας ανάγκη'
        },
        {
          property: 'og:description',
          content: 'Our company with more than 10 years of experience, offers unique IT and digital content design solutions',
          content_el: 'Η Εταιρεία μας με πάνω απο 10 χρόνια εμπειρίας στο χώρο, σας προσφέρει μοναδικές λύσεις πληροφορικής και δημιουργίας ψηφιακού περιεχομένου για κάθε σας ανάγκη'
        }
      ]
    }
  },
  {
    path: '/',
    name: 'portfolio',
    hash: 'portfolio',
    component: Home
  },
  {
    path: '/',
    name: 'methodology',
    hash: 'methodology',
    component: Home
  },
  {
    path: '/',
    name: 'contactus',
    hash: 'contactus',
    component: Home
  },
  {
    path: '/portfoliodetails_litcom',
    name: 'portfoliodetails_litcom',
    component: PortfolioDetails,
    props: true
  },
  {
    path: '/portfoliodetails_keema',
    name: 'portfoliodetails_keema',
    component: PortfolioDetails,
    props: true
  },
  {
    path: '/portfoliodetails_feelmusic',
    name: 'portfoliodetails_feelmusic',
    component: PortfolioDetails,
    props: true
  },
  {
    path: '/portfoliodetails_cosmoplay',
    name: 'portfoliodetails_cosmoplay',
    component: PortfolioDetails,
    props: true
  },
  {
    path: '/portfoliodetails_cosmotebooks',
    name: 'portfoliodetails_cosmotebooks',
    component: PortfolioDetails,
    props: true
  },
  {
    path: '/portfoliodetails_floodsypeka',
    name: 'portfoliodetails_floodsypeka',
    component: PortfolioDetails,
    props: true
  },
  {
    path: '/portfoliodetails_mindjam',
    name: 'portfoliodetails_mindjam',
    component: PortfolioDetails,
    props: true
  },
  {
    path: '/portfoliodetails_helmi',
    name: 'portfoliodetails_helmi',
    component: PortfolioDetails,
    props: true
  },
  {
    path: '/portfoliodetails_matoufrance',
    name: 'portfoliodetails_matoufrance',
    component: PortfolioDetails,
    props: true
  },
  {
    path: '/portfoliodetails_troxaia',
    name: 'portfoliodetails_troxaia',
    component: PortfolioDetails,
    props: true
  },
  {
    path: '/portfoliodetails_emtecom',
    name: 'portfoliodetails_emtecom',
    component: PortfolioDetails,
    props: true
  },
  {
    path: '/portfoliodetails_chania',
    name: 'portfoliodetails_chania',
    component: PortfolioDetails,
    props: true
  }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior: function (to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth',
        offset: { x: 0, y: 60 }
      }
    } else {
      return {
        x: 0, y: 0,
        behavior: 'smooth'
      }
    }
  }
});

router.beforeEach((to, from, next) => {
  console.log('from: ', from.path);
  console.log('to: ', to.path);
  if (to.path !== from.path) {
    if (to.path === '/') {
      /* if (localStorage.getItem('lang')) {
        var stored_lang = localStorage.getItem('lang');
        next({ path: to.path + stored_lang });
      } else {
        var browser_lang = navigator.language;
        var lang = 'en';
        if (browser_lang.includes('el')) {
          lang = 'el';
        }

        next({ path: to.path + lang });
        localStorage.setItem('lang', lang);
      } */
      next();
    } else {
      next();
    }
  } else {
    if(to.path === '/')
    {
        next();
    }
  }
});


/* 
router.beforeEnter((to, from, next) => {
  console.log(Vue.i18n);
  if ((new RegExp(`^/${Vue.lang}$`))
    .test(to.path)
    ||
    (new RegExp(`^/${Vue.lang}/`))
      .test(to.path)) {
    next();
  } else {

    next({ path: `/${Vue.lang}${to.path}` })
  }
}); */

export default router
