import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import AOS from 'aos';
import 'aos/dist/aos.css';

Vue.config.productionTip = false;

require('./assets/css/style.css');
require('./assets/vendor/icofont/icofont.min.css');
require('./assets/vendor/boxicons/css/boxicons.min.css');

Vue.mixin({
  methods: {
    updatemetadata() {
      var to = this.$route;

      var final_title = 'JoyFoundry LTD';
      var final_description = '';
      if(this.$i18n.locale === 'el')
      {
        if(to.meta.title_el)
        {
          final_title = to.meta.title_el;
        } else {
          final_title = to.meta.title;
        }

        if(to.meta !== null && typeof to.meta !== 'undefined')
        {
          if(to.meta.metaTags !== null && typeof to.meta.metaTags !== 'undefined')
          {
            final_description = to.meta.metaTags[0].content_el;
          }              
        }
      } else {
        final_title = to.meta.title;
        if(to.meta !== null && typeof to.meta !== 'undefined')
        {
          if(to.meta.metaTags !== null && typeof to.meta.metaTags !== 'undefined')
          {
            final_description = to.meta.metaTags[0].content;
          }               
        }
      }

      document.title = final_title;
      document.querySelector('meta[name="og:description"]').setAttribute("content", final_description);
      document.querySelector('meta[name="description"]').setAttribute("content", final_description);
      
    }
  }
});

new Vue({
  router,
  store,
  created () {
    AOS.init({
      duration: 800,
      easing: "ease-in-out"
    });
  },
  render: h => h(App),
  i18n,
  mounted: () => document.dispatchEvent(new Event("x-app-rendered"))
}).$mount('#app');
