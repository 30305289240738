<template>
<div class="page">
    <Header :hashero="hashero"></Header>
    <main id="main" :style="[ hashero ? { 'padding-top': '0px !important' } : { 'padding-top': '100px !important' } ]">
        <div class="container">
            <div class="row">
                <div class="col-sm">
                    <h5 class="title">{{ $t('companyprofile') }}</h5><br />
                </div>
            </div>
            <div class="row">
                <div class="profile-text col-sm">
                    <p>{{ $t('profile_a') }}</p>
                    <p>{{ $t('profile_b') }}</p>
                    <p>{{ $t('profile_c') }}</p>
                </div>
                <div class="col-sm">
                    <img src="./../assets/img/services-2.jpg" alt="Services 2">
                </div>
            </div>
            <div class="row">
                <br/>
            </div>
            <div class="row">
                <div class="col-sm">
                    <h5 class="title">{{ $t('profile_vision') }}</h5><br />
                </div>
            </div>
            <div class="row">
                <div class="profile-text col-sm">
                    <p v-html="$t('profile_vision_desc')"></p>
                </div>
            </div>
            <div class="row">
                <div class="col-sm">
                    <h5 class="title">{{ $t('profile_values') }}</h5><br />
                </div>
            </div>
            <div class="row">
                <div class="profile-text col-sm">
                    <p v-html="$t('profile_values_desc')"></p>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </main><!-- End #main -->
    <a href="#" class="back-to-top"><i class="icofont-simple-up"></i></a>
</div>
</template>

<script>
//import AOS from 'aos';

import Header from './../components/Header';
import Footer from './../components/Footer';

export default {
    name: 'Home',
    props: {
        title: Object,
        description: Object,
    },
    components: {
        Footer,
        Header
    },
    data() {
        return {
            hashero: false,
            data: {}
        }
    },
    created() {

    },
    mounted() {
        this.updatemetadata();
    },
    methods: {
        backBtnClicked() {
            this.$router.go(-1);
        }
    }
}
</script>

<style scoped>
.backbtn {
    text-align: left;
    padding-bottom: 30px;
}

.backbtn:hover {
    color: #fdc134;
    cursor: pointer;
}

.backbtn i {
    font-size: 14pt;
    padding-right: 10px;
}

.backbtn h6 {
    font-size: 14pt;
}

.container {
    padding-top: 60px;
    padding-bottom: 60px;
    min-height: 600px;
}

.title {
    color: #fdc134;
    text-align: left;
}

.description {
    text-align: left;
}

.profile-text {
    text-align: left;
}
</style>
