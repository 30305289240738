<template>
<!-- ======= Team Section ======= -->
<section id="team" class="team section-bg">
    <div class="container">

        <div class="section-title">
            <h2 data-aos="fade-in">{{ $t('team') }}</h2>
            <p data-aos="fade-in">{{ $t('team_desc') }}</p>
        </div>

        <div class="row">
            <div class="col-xl-3 col-lg-4 col-md-6">
                <div class="member" data-aos="fade-up">
                    <div class="pic"><img src="./../assets/img/team/team-1.jpg" alt=""></div>
                    <h4>Takakis Michael</h4>
                    <span>Chief Executive Officer</span>
                    <div class="social">
                        <a href=""><i class="icofont-twitter"></i></a>
                        <a href=""><i class="icofont-facebook"></i></a>
                        <a href=""><i class="icofont-instagram"></i></a>
                        <a href=""><i class="icofont-linkedin"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-xl-3 col-lg-4 col-md-6">
                <div class="member" data-aos="fade-up" data-aos-delay="100">
                    <div class="pic"><img src="./../assets/img/team/team-2.jpg" alt=""></div>
                    <h4>Raftopoulos Ioannis</h4>
                    <span>Product Manager</span>
                    <div class="social">
                        <a href=""><i class="icofont-twitter"></i></a>
                        <a href=""><i class="icofont-facebook"></i></a>
                        <a href=""><i class="icofont-instagram"></i></a>
                        <a href=""><i class="icofont-linkedin"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-xl-3 col-lg-4 col-md-6">
                <div class="member" data-aos="fade-up" data-aos-delay="200">
                    <div class="pic"><img src="./../assets/img/team/team-3.jpg" alt=""></div>
                    <h4>William Anderson</h4>
                    <span>CTO</span>
                    <div class="social">
                        <a href=""><i class="icofont-twitter"></i></a>
                        <a href=""><i class="icofont-facebook"></i></a>
                        <a href=""><i class="icofont-instagram"></i></a>
                        <a href=""><i class="icofont-linkedin"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-xl-3 col-lg-4 col-md-6">
                <div class="member" data-aos="fade-up" data-aos-delay="300">
                    <div class="pic"><img src="./../assets/img/team/team-4.jpg" alt=""></div>
                    <h4>Amanda Jepson</h4>
                    <span>Accountant</span>
                    <div class="social">
                        <a href=""><i class="icofont-twitter"></i></a>
                        <a href=""><i class="icofont-facebook"></i></a>
                        <a href=""><i class="icofont-instagram"></i></a>
                        <a href=""><i class="icofont-linkedin"></i></a>
                    </div>
                </div>
            </div>
        </div>

    </div>
</section><!-- End Team Section -->
</template>

<script>
export default {
    name: 'Team'
}
</script>

<style scoped lang="scss">

</style>
