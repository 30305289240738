<template>
<div class="page">
    <Header :hashero="hashero"></Header>
    <main id="main" :style="[ hashero ? { 'padding-top': '0px !important' } : { 'padding-top': '100px !important' } ]">
        <div class="container">
            <div class="row">
                <div class="backbtn col-sm">
                    <h6 @click="backBtnClicked()"><i class="icofont-rounded-left"></i>Επιστροφή</h6>
                </div>
            </div>
            <div class="row">
                <div class="col-sm">
                    <h5 class="title">{{ getTitle() }}</h5><br/>
                </div>
            </div>
            <div class="row">
                <div class="col-sm">
                    <div v-html="getDescription()" class="description"></div>
                </div>
                <div class="col-sm">
                    <img :src="getImage()" alt="Portfolio Details">
                </div>                
            </div>
        </div>
        <Portfolio></Portfolio>
        <Footer></Footer>
    </main><!-- End #main -->
    <a href="#" class="back-to-top"><i class="icofont-simple-up"></i></a>
</div>
</template>

<script>
//import AOS from 'aos';

import Header from './../components/Header';
import Footer from './../components/Footer';
import Portfolio from './../components/Portfolio';

export default {
    name: 'Home',
    props: {
        title: Object,
        description: Object,
    },
    components: {
        Portfolio,
        Footer,
        Header
    },
    data() {
        return {
            hashero: false,
            data: {
                title: {},
                description: {},
                image: ''
            }
        }
    },
    created() {
        var path = this.$router.currentRoute.path;
        var pagekey = path.replace('/portfolioDetails_', '');
        console.log('pagekey: ',pagekey);
        if (pagekey !== null && typeof pagekey !== 'undefined') {
            this.data = this.$store.state.portfolio[pagekey];
        }
    },
    mounted() {
        this.updatemetadata();
    },
    methods: {
        backBtnClicked() {
            this.$router.go(-1);
        },
        getTitle() {
            var final = '';
            if(this.data !== null && typeof this.data !== 'undefined')
            {
                var title = this.data.title;

                if (title.en !== null && typeof title.en !== 'undefined') {
                    final = title[this.$i18n.locale];
                    localStorage.setItem('portfolio_title', JSON.stringify(title));
                } else {
                    if (localStorage.getItem('portfolio_title')) {
                        var stored = JSON.parse(localStorage.getItem('portfolio_title'));
                        final = stored[this.$i18n.locale];
                    }
                }
            }
            

            return final;
        },
        getDescription() {
            var final = '';
            if(this.data !== null && typeof this.data !== 'undefined')
            {
                var description = this.data.description;
                if (description.en !== null && typeof description.en !== 'undefined') {
                    final = description[this.$i18n.locale];
                    localStorage.setItem('portfolio_desc', JSON.stringify(description));
                } else {
                    if (localStorage.getItem('portfolio_desc')) {
                        var stored = JSON.parse(localStorage.getItem('portfolio_desc'));
                        final = stored[this.$i18n.locale];
                    }
                }
            }            

            return final;
        },
        getImage() {
            var finalimage = '';
            if(this.data !== null && typeof this.data !== 'undefined')
            {
                finalimage = this.data.image;
                if(this.data.image2)
                {
                    finalimage = this.data.image2;
                }
            }

            var returned = '';
            try {
                console.log('process.env.NODE_ENV: ',process.env.NODE_ENV);
                if(process.env.NODE_ENV === 'production')
                {
                    returned = require(`@/assets/img/portfolio/${finalimage}`);
                } else {
                    console.log('DEV: ', finalimage);
                    returned = require(`@/assets/img/portfolio/${finalimage}`);
                }                
            } catch(ex)
            {
                console.log(ex);
            }
            
            return returned;
        }
    }
}
</script>

<style scoped>
.backbtn {
    text-align: left;
    padding-bottom: 30px;
}

.backbtn:hover {
    color: #fdc134;
    cursor: pointer;
}

.backbtn i {
    font-size: 14pt;    
    padding-right: 10px;
}

.backbtn h6 {
    font-size: 14pt;
}

.container {
    padding-top: 60px;
    padding-bottom: 60px;
    min-height: 600px;
}

.title {
    color: #fdc134;
    text-align: left;
}

.description {
    text-align: left;
}
</style>
