<template>
<div class="page">
    <Header :hashero="hashero"></Header>
    <main id="main" :style="[ hashero ? { 'padding-top': '0px !important' } : { 'padding-top': '100px !important' } ]">
        <div class="container">
            <div class="row">
                <div class="img-container col-sm">
                    <img src="./../assets/img/header_eshop.jpg" alt="Δωρεάν e-Shop επιδότηση ΕΣΠΑ 100%" />
                </div>
            </div>
            <div class="row">
                <div class="col-sm">
                    <br />
                    <br />
                    <h3 class="title">{{ $t('eshop_title') }}</h3>
                    <br />
                    <h4 class="subtitle">{{ $t('eshop_title_b') }}</h4>
                    <br />
                </div>
            </div>

            <div class="row">
                <div class="profile-text col-sm">
                    <div v-html="$t('eshop_main_a')"></div>
                </div>
            </div>
            <div class="row">
                <br />
            </div>
            <div class="row">
                <div class="col-sm">
                    <h4 class="title">{{ $t('eshop_title_2') }}</h4>
                    <br />
                </div>
            </div>
            <div class="row">
                <div class="profile-text col-sm">
                    <div v-html="$t('eshop_main_b')"></div>
                </div>
            </div>
            <div class="row">
                <div class="profile-text col-sm">
                    <div v-html="$t('eshop_main_b2')"></div>
                </div>
            </div>
            <div class="row">
                <div class="profile-text col-sm">
                    <div v-html="$t('eshop_main_c')"></div>
                </div>
            </div>
            <div class="row">
                <div class="profile-text col-sm">
                    <div v-html="$t('eshop_main_c2')"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm">
                    <h4 class="title">{{ $t('eshop_title_3') }}</h4>
                    <br />
                </div>
            </div>
            <div class="row">
                <div class="profile-text col-sm">
                    <p v-html="$t('eshop_main_d')"></p>
                </div>
            </div>
            <div class="row">
                <div class="col-sm">
                    <h4 class="title">{{ $t('eshop_title_4') }}</h4>
                    <br />
                </div>
            </div>
            <div class="row">
                <div class="profile-text col-sm">
                    <p v-html="$t('eshop_main_e')"></p>
                </div>
            </div>
            <div class="row">
                <div class="col-sm">
                    <h4 class="title">{{ $t('eshop_title_5') }}</h4>
                    <br />
                </div>
            </div>
            <div class="row">
                <div class="profile-text col-sm">
                    <div v-html="$t('eshop_main_f')"></div>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </main>
    <!-- End #main -->
    <a href="#" class="back-to-top">
        <i class="icofont-simple-up"></i>
    </a>
</div>
</template>

<script>
//import AOS from 'aos';

import Header from "./../components/Header";
import Footer from "./../components/Footer";

export default {
    name: "Home",
    props: {
        title: Object,
        description: Object
    },
    components: {
        Footer,
        Header
    },
    data() {
        return {
            hashero: false,
            data: {}
        };
    },
    created() {},
    mounted() {
        gtag('event', 'page_view', {
            page_title: '<Page Title>',
            page_location: '<Page Location>',
            page_path: '<Page Path>',
            send_to: '<GA_MEASUREMENT_ID>'
        })
        this.updatemetadata();
    },
    methods: {
        backBtnClicked() {
            this.$router.go(-1);
        }
    }
};
</script>

<style scoped>
h3 {
    /* color: #114aa1 !important; */
    font-size: 22pt;
}

.profile-text h5 {
    font-weight: bold;
}

.backbtn {
    text-align: left;
    padding-bottom: 30px;
}

.backbtn:hover {
    color: #fdc134;
    cursor: pointer;
}

.backbtn i {
    font-size: 14pt;
    padding-right: 10px;
}

.backbtn h6 {
    font-size: 14pt;
}

.container {
    padding-top: 60px;
    padding-bottom: 60px;
    min-height: 600px;
}

.title {
    color: #fdc134;
    text-align: left;
}

.subtitle {
    text-align: left;
    color: #114aa1;
    font-size: 17pt;
}

.description {
    text-align: left;
}

.profile-text {
    text-align: left;
}

.img-container {
    overflow: hidden;
    text-align: center;
}

.img-container img {
    max-width: 100%;
}
</style>
