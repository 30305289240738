<template>
<div id="panel">
    <button type="button" class="close" @click="closePanel()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>

    <div class="panel_container">
        <div class="row">
            <div class="col-sm">
                <img src="./../assets/img/services-3.jpg" :alt="element.title[$i18n.locale]">
            </div>
            <div class="col-sm">
                <h5 class="title">{{ element.title[$i18n.locale] }}</h5>
                <p class="description">{{ element.description[$i18n.locale] }}</p>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    props: {
        closefunction: Function,
        element: Object,
    },
    name: 'PortfolioPanel',
    methods: {
        closePanel() {
            console.log('closePanel');
            this.closefunction();
        }
    }
}
</script>

<style lang="scss" scoped>
#panel {
    padding: 50px;
}

.title
{
    color: #fdc134;
}


.close {
    font-size: 3rem;
    position: absolute;
    top: 20px;
    right: 20px;
}

button:focus {
    outline: 0px;
}
</style>
