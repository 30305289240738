<template>
<div class="page">
    <Header :hashero="hashero"></Header>
    <Hero></Hero>
    <main id="main" :style="[ hashero ? { 'padding-top': '0px !important' } : { 'padding-top': '100px !important' } ]">
        <Vendors></Vendors>
        <About></About>
        <Portfolio></Portfolio>
        <Services></Services>
        <!-- <Team></Team> -->
        <Methodology></Methodology>
        <Contact></Contact>
        <Footer></Footer>
        <cookie-law>
            <div class="row cookies_content" slot-scope="props">
                <div class="col-md-10">
                    <p>🍪 Our website uses cookies to ensure you get the best experience on our website. To receive the best experience in our website you must allow cookies.</p>
                </div>
                <div class="col-md-1 skew-container">
                    <button class="skew" @click="props.accept"><span>Allow Cookies</span></button>
                </div>
                <div class="col-md-1 skew-container">
                    <button class="skew" @click="props.close"><span>Decline</span></button>
                </div>
            </div>
        </cookie-law>
        <div class="floatingbanner" :class="{ flyouthidden: !flyoutvisible }">
            <button @click="CloseFlyOut()" class="closeicon"><i class="bx bx-x-circle"></i></button>
            <router-link class="btn-get-started scrollto flyout_btn" :to="{ name: 'eshop' }">{{ $t('getquote') }}</router-link>
        </div>
    </main><!-- End #main -->
    <a href="javascript:void(0)" @click="ScrollToTop()" :class="{ backtotop_show: showtotop, 'back-to-top': true }"><i class="icofont-simple-up"></i></a>
    <!-- <router-link :class="{ backtotop_show: showtotop, 'back-to-top': true }" :to="{ path: '/' }"><i class="icofont-simple-up"></i></router-link> -->
</div>
</template>

<script>
import CookieLaw from 'vue-cookie-law';

import Header from './../components/Header';
import Footer from './../components/Footer';
import Hero from './../components/Hero';

import Vendors from './../components/Vendors';
import About from './../components/About';
import Portfolio from './../components/Portfolio';
import Services from './../components/Services';
import Methodology from './../components/Methodology';
import Team from './../components/Team';
import Contact from './../components/Contact';

export default {
    name: 'Home',
    components: {
        Vendors,
        About,
        Portfolio,
        Services,
        Methodology,
        Team,
        Contact,
        Footer,
        Header,
        Hero,
        CookieLaw
    },
    data() {
        return {
            hashero: true,
            showtotop: false,
            flyoutvisible: true
        }
    },
    created() {
        window.addEventListener('scroll', this.handleScroll);
    },
    methods: {
        handleScroll(event) {
            if (window.scrollY > 100) {
                this.showtotop = true;
            } else {
                this.showtotop = false;
            }
        },
        ScrollToTop() {
            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
        },
        CloseFlyOut() {
            this.flyoutvisible = false;
        }
    }
}
</script>

<style>
.backtotop_show {
    visibility: visible !important;
    opacity: 1 !important;
}

.cookies_content {
    text-align: left;
    width: 100%;
}

.cookies_content p {
    margin: 0px;
}

.Cookie--base {
    background: #e8eaec;
    padding: 10pt;
}

.skew {
    border: 0px solid;
    background: #fda334;
    padding: 5px;
    height: 100%;
    font-size: 10pt;
}

.skew-container {
    text-align: center;
}

.floatingbanner {
    position: fixed;
    right: 0px;
    bottom: 100px;
    background: url('./../assets/img/free_eshop_flyout.jpg') no-repeat;
    width: 250px;
    height: 250px;
    display: block;
    z-index: 1000;
}

.closeicon {
    border: 0px solid;
    background: transparent;
    color: #ffffff;
    font-size: 18pt;
    position: absolute;
    top: 5px;
    right: 5px;
}

.closeicon:focus, .closeicon:hover {
    border: 0px solid;
    background: transparent;
    outline: 0px;
}

.flyouthidden {
    display: none !important;
}

.flyout_btn {
    position: absolute;
    bottom: 15px;
    right: 58px;
    border: 2px solid #fda334;
    background: #fda334;
    color: #213b52;
        padding: 2px 15px 2px 15px;
}
</style>
