<template>
<!-- ======= Footer ======= -->
<footer id="footer">

    <div class="footer-top">

        <div class="container">

            <!-- <div class="row  justify-content-center">
                <div class="col-lg-6">
                    <h3>Bocor</h3>
                    <p>Et aut eum quis fuga eos sunt ipsa nihil. Labore corporis magni eligendi fuga maxime saepe commodi placeat.</p>
                </div>
            </div>

            <div class="row footer-newsletter justify-content-center">
                <div class="col-lg-6">
                    <form action="" method="post">
                        <input type="email" name="email" placeholder="Enter your Email"><input type="submit" value="Subscribe">
                    </form>
                </div>
            </div> -->

            <div class="social-links">
                <a href="https://twitter.com/joyfoundry" target="_blank" class="twitter"><i class="bx bxl-twitter"></i></a>
                <a href="https://www.facebook.com/joyfoundry" target="_blank" class="facebook"><i class="bx bxl-facebook"></i></a>
                <a href="https://www.instagram.com/joyfoundry" target="_blank" class="instagram"><i class="bx bxl-instagram"></i></a>
                <!-- <a href="#" class="google-plus"><i class="bx bxl-skype"></i></a> -->
                <a href="https://www.linkedin.com/company/joyfoundry" target="_blank" class="linkedin"><i class="bx bxl-linkedin"></i></a>
            </div>

        </div>
    </div>

    <div class="container footer-bottom clearfix">
        <div class="copyright">
            &copy; Copyright <strong><span>JoyFoundry</span></strong>. All Rights Reserved Αρ. Γ.Ε.ΜΗ 129357701000
        </div>
    </div>
</footer><!-- End Footer -->
</template>

<script>
export default {
    name: 'Footer'
}
</script>

<style scoped lang="scss">
#footer .footer-top {
    padding: 20px 0px 40px 0px;
}
</style>
