<template>
<!-- ======= About Section ======= -->
<section id="about" class="about section-bg">
    <div class="container">

        <div class="row">
            <div class="image col-xl-5 d-flex align-items-stretch justify-content-center justify-content-lg-start"></div>
            <div class="col-xl-7 pl-0 pl-lg-5 pr-lg-1 d-flex align-items-stretch">
                <div class="content d-flex flex-column justify-content-center">
                    <h1 class="about_title" data-aos="fade-in" data-aos-delay="100">{{ $t('about_title') }}</h1>
                    <br/>
                    <p class="about_title2" data-aos="fade-in" data-aos-delay="100">{{ $t('about_title2') }}</p>
                    <div class="row">                        
                        <div class="col-md-10 icon-box" data-aos="fade-up">
                            <i class="bx bx-building"></i>
                            <p>{{ $t('about_header_a') }}</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 icon-box" data-aos="fade-up" data-aos-delay="100">
                            <i class="bx bx-atom"></i>
                            <p>{{ $t('about_header_b') }}</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 icon-box" data-aos="fade-up" data-aos-delay="200">
                            <i class="bx bx-bulb"></i>
                            <p>{{ $t('about_header_c') }}</p>
                        </div>
                    </div>  
                    <div class="row">
                        <div class="col-md-12 icon-box" data-aos="fade-up" data-aos-delay="200">
                            <i class="bx bx-badge-check"></i>
                            <p>{{ $t('about_header_d') }}</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 icon-box" data-aos="fade-up" data-aos-delay="200">
                            <i class="bx bx-coin-stack"></i>
                            <p>{{ $t('about_header_e') }}</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 icon-box" data-aos="fade-up" data-aos-delay="200">
                            <i class="bx bx-award"></i>
                            <p>{{ $t('about_header_f') }}</p>
                        </div>
                    </div>                  
                </div><!-- End .content-->
            </div>
        </div>

    </div>
</section><!-- End About Section -->
</template>

<script>
export default {
    name: 'About'
}
</script>

<style lang="scss" scoped>

.icon-box i {
    font-size: 38px !important;
}

.icon-box p {
    text-align: left;
    padding-left: 5px;
    padding-top: 8px;
    font-weight: 200;
    font-size: 18px;
}

.about_title, .about_title2 {
    text-align: left;
}

.about_title
{
    color: #114aa1;
}

h1 {
    font-size: 20pt;
}

.content {
    padding: 0px !important;
}

.icon-box p {
    
}
</style>
