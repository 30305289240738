<template>
<!-- <q-select
    v-model="lang"
    :options="langOptions"    
    dense
    borderless
    emit-value
    map-options
    options-dense
    style="max-width: 150px"
  >
    <template v-slot:selected-item="scope">
        <span class="ellipsis">{{ scope.opt.label }}</span>
    </template>
  </q-select> -->
<ul class="nav">
    <li class="nav-item">
        <a class="nav-link" @click="lang('en')" href="javascript:void(0)">EN</a>
    </li>
    <li class="nav-item">
        <a class="nav-link" @click="lang('el')" href="javascript:void(0)">EL</a>
    </li>
</ul>
</template>

<script>
export default {
    data() {
        return {
            currentlang: this.$i18n.locale,
            langOptions: [{
                    value: 'en',
                    label: 'English',
                    short: 'EN'
                },
                {
                    value: 'el',
                    label: 'Ελληνικά',
                    short: 'ΕΛ'
                }
            ]
        }
    },
    methods: {
        lang(lang) {
            this.$i18n.locale = lang;
            localStorage.setItem('lang', lang);
            document.documentElement.setAttribute('lang', lang);
            this.$router.push({name: this.$route.name, hash: this.$route.hash, params: {lang: lang}});
        }
    }
}
</script>

<style lang="scss" scoped>
.q-field--dense {
    padding-left: 0px !important;
}

.nav-menu a {
    /* color: #ffffff; */
    padding: 10px 8px;
}

.home-lang-switcher {
    padding-left: 60px;
}
</style>
