<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
    watch: {
        '$route'(to, from) {
          var final_title = 'JoyFoundry LTD';
          var final_description = '';
          if(this.$i18n.locale === 'el')
          {
            if(to.meta.title_el)
            {
              final_title = to.meta.title_el;
            } else {
              final_title = to.meta.title;
            }

            if(to.meta !== null && typeof to.meta !== 'undefined')
            {
              if(to.meta.metaTags !== null && typeof to.meta.metaTags !== 'undefined')
              {
                final_description = to.meta.metaTags[0].content_el;
              }              
            }
          } else {
            final_title = to.meta.title;
            if(to.meta !== null && typeof to.meta !== 'undefined')
            {
              if(to.meta.metaTags !== null && typeof to.meta.metaTags !== 'undefined')
              {
                final_description = to.meta.metaTags[0].content;
              }               
            }
          }

          document.title = final_title;
          document.querySelector('meta[name="og:description"]').setAttribute("content", final_description);
          document.querySelector('meta[name="description"]').setAttribute("content", final_description);          
        }
    }
}
</script>

<style lang="scss">
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}
</style>
