<template>
<!-- ======= Header ======= -->
<header id="header" :class="{ overlay: scrolledDown, bgup: !hashero }">
    <div class="container d-flex">
        <div class="logo mr-auto">
            <a href="/"><img src="./../assets/img/logo_long_small_white.png" alt="JoyFoundry" /></a>
        </div>

        <nav class="nav-menu d-none d-lg-block">
            <ul>
                <!-- <li class="active"><a href="/">{{ $t('home') }}</a></li>
                <li><a href="javascript:void(0)" @click="NavigateTo('about')">{{ $t('about') }}</a></li>
                <li><a href="javascript:void(0)" @click="NavigateTo('portfolio')">{{ $t('portfolio') }}</a></li>
                <li><a href="javascript:void(0)" @click="NavigateTo('services')">{{ $t('services') }}</a></li>                
                <li><a href="javascript:void(0)" @click="NavigateTo('contactus')">{{ $t('contactus') }}</a></li> -->


                <li><router-link :to="{ name: 'home' }">{{ $t('home') }}</router-link></li>
                <li><router-link :to="{ name: 'profile' }">{{ $t('profile') }}</router-link></li>
                <li><router-link :to="{ name: 'portfolio', hash: '#portfolio' }">{{ $t('portfolio') }}</router-link></li>
                <li><router-link :to="{ name: 'services', hash: '#services' }">{{ $t('services') }}</router-link></li>                
                <li><router-link :to="{ name: 'methodology', hash: '#methodology' }">{{ $t('methodology') }}</router-link></li>
                <li><router-link @click="updateActivePath()" :to="{ name: 'contactus', hash: '#contactus' }">{{ $t('contactus') }}</router-link></li>
                
                <LanguageSwither class="home-lang-switcher" />
            </ul>
        </nav><!-- .nav-menu -->

    </div>
</header><!-- End Header -->
</template>

<script>
import LanguageSwither from './../components/LanguageSwitcher';

export default {
    name: 'Header',
    props: {
        hashero: Boolean
    },
    components: {
        LanguageSwither
    },
    data() {
        return {
            scrolledDown: false
        }
    },
    created() {
        window.addEventListener('scroll', this.handleScroll);
    },
    methods: {
        updateActivePath() {
            
        },
        NavigateTo(target_path) {
            var lang = localStorage.getItem('lang');
            this.$router.push({
                path: target_path+'/'+lang
            }).catch(()=>{});
        },
        handleScroll(event) {
            // Any code to be executed when the window is scrolled
            if (window.scrollY > 5) {
                this.scrolledDown = true;
            } else {
                this.scrolledDown = false;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.bgup {
    background: #0a2f6a !important;
}
.router-link-exact-active {
    color: #ffffff;
}

#header .logo img {
    margin-top: 10px;
}
</style>
