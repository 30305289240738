<template>
<!-- ======= Services Section ======= -->
<section id="services" class="services section-bg">
    <div class="container">

        <div class="section-title">
            <h2 data-aos="fade-in">{{ $t('services_cap') }}</h2>
            <p data-aos="fade-in">{{ $t('services_description') }}</p>
        </div>

        <div class="row">
            <div class="col-md-4 d-flex align-items-stretch" data-aos="fade-up">
                <div class="card">
                    <div class="card-img">
                        <img src="./../assets/img/services/service_webdev.jpg" :alt="$t('service_webdev')">
                    </div>
                    <div class="card-body">
                        <h5 class="card-title"><a href="javascript:void(0)">{{ $t('service_webdev') }}</a></h5>
                        <p class="card-text">{{ $t('service_desc_webdev') }}</p>
                    </div>
                </div>
            </div>
            <div class="col-md-4 d-flex align-items-stretch" data-aos="fade-up">
                <div class="card">
                    <div class="card-img">
                        <img src="./../assets/img/services/service_mobiledev.jpg" :alt="$t('service_mobiledev')">
                    </div>
                    <div class="card-body">
                        <h5 class="card-title"><a href="javascript:void(0)">{{ $t('service_mobiledev') }}</a></h5>
                        <p class="card-text">{{ $t('service_desc_mobiledev') }}</p>
                    </div>
                </div>

            </div>
            <div class="col-md-4 d-flex align-items-stretch" data-aos="fade-up">
                <div class="card">
                    <div class="card-img">
                        <img src="./../assets/img/services/service_platformdev.jpg" :alt="$t('service_platformdev')">
                    </div>
                    <div class="card-body">
                        <h5 class="card-title"><a href="javascript:void(0)">{{ $t('service_platformdev') }}</a></h5>
                        <p class="card-text">{{ $t('service_desc_platformdev') }}</p>
                    </div>
                </div>
            </div>
            <div class="col-md-4 d-flex align-items-stretch" data-aos="fade-up">
                <div class="card">
                    <div class="card-img">
                        <img src="./../assets/img/services/service_design.jpg" :alt="$t('service_design')">
                    </div>
                    <div class="card-body">
                        <h5 class="card-title"><a href="javascript:void(0)">{{ $t('service_design') }}</a></h5>
                        <p class="card-text">{{ $t('service_desc_design') }}</p>
                        <ul class="service_item_list">
                            <li>{{ $t('service_desc_design_list_a') }}</li>
                            <li>{{ $t('service_desc_design_list_b') }}</li>
                            <li>{{ $t('service_desc_design_list_c') }}</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-md-4 d-flex align-items-stretch" data-aos="fade-up">
                <div class="card">
                    <div class="card-img">
                        <img src="./../assets/img/services/service_marketing.jpg" :alt="$t('service_onlinemarketing')">
                    </div>
                    <div class="card-body">
                        <h5 class="card-title"><a href="javascript:void(0)">{{ $t('service_onlinemarketing') }}</a></h5>
                        <p class="card-text">{{ $t('service_desc_onlinemarketing') }}</p>
                        <ul class="service_item_list">
                            <li>Webpage SEO</li>
                            <li>Mobile ASO</li>
                            <li>Facebook Ads</li>
                            <li>Instagram Ads</li>
                            <li>Google Ads</li>
                            <li>Social Pages</li>
                            <li>Email Marketing</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-md-4 d-flex align-items-stretch" data-aos="fade-up">
                <div class="card">
                    <div class="card-img">
                        <img src="./../assets/img/services/service_business_development.jpg" :alt="$t('service_businessdev')">
                    </div>
                    <div class="card-body">
                        <h5 class="card-title"><a href="javascript:void(0)">{{ $t('service_businessdev') }}</a></h5>
                        <p class="card-text">{{ $t('service_desc_businessdev') }}</p>
                    </div>
                </div>
            </div>
            <div class="col-md-4 d-flex align-items-stretch" data-aos="fade-up">
                <div class="card">
                    <div class="card-img">
                        <img src="./../assets/img/services/service_gamedev.jpg" :alt="$t('service_videogames')">
                    </div>
                    <div class="card-body">
                        <h5 class="card-title"><a href="javascript:void(0)">{{ $t('service_videogames') }}</a></h5>
                        <p class="card-text">{{ $t('service_desc_videogames') }}</p>
                    </div>
                </div>
            </div>
            <div class="col-md-4 d-flex align-items-stretch" data-aos="fade-up">
                <div class="card">
                    <div class="card-img">
                        <img src="./../assets/img/services/service_videoprod.jpg" :alt="$t('service_videoprod')">
                    </div>
                    <div class="card-body">
                        <h5 class="card-title"><a href="javascript:void(0)">{{ $t('service_videoprod') }}</a></h5>
                        <p class="card-text">{{ $t('service_desc_videoprod') }}</p>
                    </div>
                </div>
            </div>
            <div class="col-md-4 d-flex align-items-stretch" data-aos="fade-up">
                <div class="card">
                    <div class="card-img">
                        <img src="./../assets/img/services/service_sounddesignt.jpg" :alt="$t('service_sounddesign')">
                    </div>
                    <div class="card-body">
                        <h5 class="card-title"><a href="javascript:void(0)">{{ $t('service_sounddesign') }}</a></h5>
                        <p class="card-text">{{ $t('service_desc_sounddesign') }}</p>
                    </div>
                </div>
            </div>
        </div>

    </div>
</section><!-- End Services Section -->
</template>

<script>
export default {
    name: 'Services'
}
</script>

<style lang="scss" scoped>
.service_item_list {
    list-style: none;
    padding-left: 0px;
    font-weight: 600;
}
</style>
