<template>
<!-- ======= Vendors Section ======= -->
<section id="vendors" class="vendors section-bg">
    <VueSlickCarousel v-bind="settings" :arrows="false" :dots="false" :infinite="true" :slidesToShow="5" :centerMode="true" :centerPadding="'20px'" :slidesToScroll="3" :autoplay="true" :speed="3000" :autoplaySpeed="6000">
        <div v-for="vendor in vendors" :key="vendor.key" class="client-logo">
            <img height="80" :src="getImage(vendor.image)" alt="Vendor">
        </div>
    </VueSlickCarousel>
</section><!-- End Vendors Section -->
</template>

<script>
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

import VueSlickCarousel from 'vue-slick-carousel'

export default {
    name: 'Vendors',
    props: {
        msg: String
    },
    components: {
        VueSlickCarousel
    },
    data() {
        return {
            path: './../assets/img/vendors/',
            settings: {
                responsive: [{
                    "breakpoint": 550,
                    "settings": {
                        "slidesToShow": 2,
                        "slidesToScroll": 2,
                        "initialSlide": 2
                    }
                },
                {
                    "breakpoint": 700,
                    "settings": {
                        "slidesToShow": 3,
                        "slidesToScroll": 2,
                        "initialSlide": 2
                    }
                },
                {
                    "breakpoint": 900,
                    "settings": {
                        "slidesToShow": 4,
                        "slidesToScroll": 2,
                        "initialSlide": 2
                    }
                },
                {
                    "breakpoint": 1300,
                    "settings": {
                        "slidesToShow": 5,
                        "slidesToScroll": 2,
                        "initialSlide": 2
                    }
                },
                {
                    "breakpoint": 1900,
                    "settings": {
                        "slidesToShow": 6,
                        "slidesToScroll": 2,
                        "initialSlide": 2
                    }
                }]
            },
            vendors: [{
                    key: 1,
                    image: 'vendor_logo1.png'
                },
                {
                    key: 2,
                    image: 'vendor_logo2.png'
                },
                {
                    key: 3,
                    image: 'vendor_logo3.png'
                },
                {
                    key: 4,
                    image: 'vendor_logo4.png'
                },
                {
                    key: 5,
                    image: 'vendor_logo5.png'
                },
                {
                    key: 6,
                    image: 'vendor_logo6.png'
                },
                {
                    key: 7,
                    image: 'vendor_logo7.png'
                },
                {
                    key: 8,
                    image: 'vendor_logo8.png'
                },
                {
                    key: 9,
                    image: 'vendor_logo9.png'
                },
                {
                    key: 10,
                    image: 'vendor_logo10.png'
                }
            ]
        }
    },
    methods: {
        getImage(image) {
            var finalpath = this.path + `${image}`;
            return require(`./../assets/img/vendors/${image}`);
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
#vendors {
    /* max-height: 250px; */
}

.container {
    max-height: 150px;
}

.client-logo {
    padding: 0px 0px;
    max-height: 100px;
}

.client-logo img {
    max-width: 100%;
    max-height: 100%;
    margin: 0px auto;
}

section {
    padding: 20px 0px;
}
</style>
