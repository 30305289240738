<template>
<!-- ======= Features Section ======= -->
<section id="methodology" class="features section-bg">
    <div class="container">

        <div class="section-title">
            <h2 data-aos="fade-in">{{ $t('methodology_cap') }}</h2>
            <p data-aos="fade-in">{{ $t("methodology_description") }}</p>
        </div>

        <div class="row content">
            <div class="col-md-5" data-aos="fade-right">
                <img src="./../assets/img/methodology/methodology_a.png" class="img-fluid" :alt="$t('methodology_title_a')">
            </div>
            <div class="col-md-7 pt-4" data-aos="fade-left">
                <h3>{{ $t('methodology_title_a') }}</h3>
                <p>
                    {{ $t('methodology_desc_a') }}
                </p>
                <p>
                    {{ $t('methodology_desc_a2') }}
                </p>
                <!-- <ul>
                    <li><i class="icofont-check"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat.</li>
                    <li><i class="icofont-check"></i> Duis aute irure dolor in reprehenderit in voluptate velit.</li>
                </ul> -->
            </div>
        </div>

        <div class="row content">
            <div class="col-md-5 order-1 order-md-2" data-aos="fade-left">
                <img src="./../assets/img/methodology/methodology_b.png" class="img-fluid" :alt="$t('methodology_title_b')">
            </div>
            <div class="col-md-7 pt-5 order-2 order-md-1" data-aos="fade-right">
                <h3>{{ $t('methodology_title_b') }}</h3>
                <!-- <p class="font-italic">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
                    magna aliqua.
                </p> -->
                <p>
                    {{ $t('methodology_desc_b') }}
                </p>
            </div>
        </div>

        <div class="row content">
            <div class="col-md-5" data-aos="fade-right">
                <img src="./../assets/img/methodology/methodology_c.png" class="img-fluid" :alt="$t('methodology_title_c')">
            </div>
            <div class="col-md-7 pt-5" data-aos="fade-left">
                <h3>{{ $t('methodology_title_c') }}</h3>
                <p>
                    {{ $t('methodology_desc_c') }}
                </p>
                <!-- <ul>
                    <li><i class="icofont-check"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat.</li>
                    <li><i class="icofont-check"></i> Duis aute irure dolor in reprehenderit in voluptate velit.</li>
                    <li><i class="icofont-check"></i> Facilis ut et voluptatem aperiam. Autem soluta ad fugiat.</li>
                </ul> -->
            </div>
        </div>

        <div class="row content">
            <div class="col-md-5 order-1 order-md-2" data-aos="fade-left">
                <img src="./../assets/img/methodology/methodology_d.png" class="img-fluid" :alt="$t('methodology_title_d')">
            </div>
            <div class="col-md-7 pt-5 order-2 order-md-1" data-aos="fade-right">
                <h3>{{ $t('methodology_title_d') }}</h3>
                <!-- <p class="font-italic">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
                    magna aliqua.
                </p> -->
                <p>
                    {{ $t('methodology_desc_d') }}
                </p>
            </div>
        </div>

    </div>
</section><!-- End Features Section -->
</template>

<script>
export default {
    name: 'Footer'
}
</script>

<style scoped lang="scss">

</style>
